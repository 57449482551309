<template>
  <div>
    <div>
      <el-form size='small' :inline='true'>
        <el-form-item label='年份选择：'>
          <span slot='label'><b>【佣金收入情况】年份：</b></span>
          <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy'
              value-format='yyyy'
              placeholder='选择年' clearable>
          </el-date-picker>
        </el-form-item>
        <!--        <el-form-item>-->
        <!--          <el-checkbox v-model='checked'>对比去年</el-checkbox>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item>-->
        <!--          <platforms-radio-group :show-all='true' size='mini' :platform-code='searchCondition.platform_code'-->
        <!--                                 @choicePlatform='choicePlatform'></platforms-radio-group>-->
        <!--        </el-form-item>-->
        <el-form-item>
          <el-button @click="getChartData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <echarts-chart :width='`100%`' :height='`500px`' :chart-option='chartOption'/>
    </div>
  </div>
</template>

<script>
import PlatformsRadioGroup from '@/pages/platform/components/PlatformsRadioGroup'
import EchartsChart from '@/components/EchartsChart'

export default {
  name: 'CommissionBarChart',
  components: {
    PlatformsRadioGroup, EchartsChart
  },
  props: {
    artistId: {
      type: String
    }
  },
  watch: {
    artistId(val) {
      this.searchCondition.artist_id = val
    },
    searchCondition: {
      deep: true,
      handler() {
        this.getChartData()
      }
    },
    checked() {
      this.getChartData()
    }
  },
  data() {
    return {
      chartOption: {},
      searchCondition: {
        platform_code: null,
        artist_id: null,
        year: null,
        start_time: null,
        end_time: null
      },
      checked: false,//是否对比去年
      newItems: []
    }
  },
  methods: {
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.platform_code) {
        condition.platform_code = this.searchCondition.platform_code
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.year) {
        condition.start_time = `${this.searchCondition.year}-01-01`
        condition.end_time = `${this.searchCondition.year}-12-31`
      }
      return condition
    },

    hasContain(items, value) {
      let include = this.newItems.find((item) => {
        return item.name === items
      })
      if (include) {
        include.value.push(value)
      } else {
        this.newItems.push({ name: items, value: [value] })
      }
    },
    async getChartData() {
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getRevenueItemStatistics(searchCondition)
      let newItems = []
      list.forEach(item => {
        newItems = [...newItems, ...item.items]
      })
      this.newItems = []
      newItems.forEach(item => {
        this.hasContain(item.time_month, item)
      })
      let data = []
      this.newItems.forEach((item) => {
        let num = 0
        item.value.forEach(i => {
          num = Math.round((Number(num) + Number(i.income_3) + Number(i.income_4) + Number(i.income_5)) * 100) / 100
        })
        item.num = num
        data.push(num)
      })
      let newData = {
        name: `佣金收入`,
        type: 'line',
        stack: 'Total',
        data: data
      }

      let legend = list[0].items.map((i) => {
        return i.time_month
      })
      this.initChartData(newData, legend)
    },


    initChartData(data, legend) {
      this.chartOption = {
        title: {
          text: '佣金收入情况'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: [],
          left: '60%'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: legend
        },
        yAxis: {
          type: 'value'
        },
        series: data
      }
    },
    defaultTime() {
      //月份选择：默认本年度第一个月到本月
      const end = new Date()
      //年份选择：默认今年
      this.searchCondition.year = end.getFullYear() + ''
    },
    choicePlatform(val) {
      // console.log('searchCondition', this.searchCondition)
      this.searchCondition.platform_code = val
    }
  },

  created() {
    this.searchCondition.artist_id = this.artistId
    this.defaultTime()
  },
  mounted() {
    this.getChartData()
  }
}
</script>

<style scoped>
#revenue_chart {
  /*border: #0000BB dashed 1px;*/
  height: 600px;
  width: 80%;
  padding: 20px;
  color: #9f00bb;
}
</style>
